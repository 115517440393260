<!-- 控制台 -->
<template>
  <div>
  <top-data></top-data>
  <div class="overviewCommand mt-5">
    <!-- 场地列表tab -->
    <el-tabs v-model="currentVenue" @tab-click="changeVenue" class="el-tabs" topline>
      <el-tab-pane v-for="item of venueList" :key="item.venueId" :label="item.venueName" :name="item.venueName">
        <normal-card title="场地基本信息" class="mt-2">
          <div style="padding: 20px;">
          <div class="tag-com">
            <el-tag size="medium" class="tag">营业时间：{{ currentVenueInfo.openingHours }}</el-tag>
            <el-tag size="medium" class="tag">场制：{{ currentVenueInfo.matchType }}人制</el-tag>
            <el-tag size="medium" class="tag">规格：{{ currentVenueInfo.areaType }}</el-tag>
            <el-tag size="medium" class="tag">默认拼场：{{ currentVenueInfo.matchType * 2 }}-{{
              currentVenueInfo.matchType * 3
            }}人</el-tag>
            <!-- <el-tag size="medium" class="tag"
              >默认飞盘：{{ currentVenueInfo.matchType * 2 }}-{{
                currentVenueInfo.matchType * 4
              }}人</el-tag
            > -->
          </div>
          <div class="select-date-com">
            <el-button type="primary" @click="prevDay">上一日</el-button>
            <el-date-picker style="margin: 0 30px" v-model="selectDate" type="date" width="120" placeholder="选择日期"
              value-format="yyyy-MM-dd" @change="changeDatePicker"></el-date-picker>
            <el-button type="primary" @click="nextDay">下一日</el-button>
          </div>
          <el-table class="schedule-table" :data="tableData" border stripe>
            <el-table-column label="开始" align="center">
              <template slot-scope="scope">
                <el-select clearable v-model="scope.row.beginTime" :disabled="timeDisabled(scope.row.beginTime)">
                  <el-option v-for="item in planTime.time" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="结束" align="center">
              <template slot-scope="scope">
                <el-select clearable v-model="scope.row.endTime" :disabled="timeDisabled(scope.row.beginTime)">
                  <el-option v-for="item in planTime.time" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="类型" align="center">
              <template slot-scope="scope">
                <el-select v-model="scope.row.bookingDetailType" @change="changeBookingDetailType(scope.row)">
                  <el-option v-for="item of bookingDetailTypeList" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="价格" align="center" width="240">
              <template slot-scope="scope">
                <div style="display: flex; align-items: center">
                  <span>￥</span>
                  <el-input-number v-model="scope.row.price" :step="1" :precision="0" :max="10000"
                    style="width: 160px; margin: 0 10px"></el-input-number>
                  <span v-if="scope.row.bookingDetailType == 10">/人</span>
                  <span v-if="scope.row.bookingDetailType == 0 || // 整场
                    scope.row.bookingDetailType == 1 || // 锁场
                    scope.row.bookingDetailType == 2 // 约战
                    ">/场</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="人数" align="center">
              <template slot-scope="scope">
                <!-- 散客(拼场) 飞盘 -->
                <el-button type="text" title="编辑人数"
                  v-if="scope.row.bookingDetailType == 10 || scope.row.bookingDetailType == 20"
                  @click="changePersonAmount(scope.row, scope.$index)">
                  {{ scope.row.min }} --- {{ scope.row.max }}
                </el-button>
                <!-- 锁场-->
                <el-button type="text" v-if="scope.row.bookingDetailType == 1"
                  @click="fieldLock(scope.row, scope.$index)">
                  信息
                </el-button>
                <!-- 约战 -->
                <el-button type="text" v-if="scope.row.bookingDetailType == 2" @click="aboutWar(scope.row, scope.$index)">
                  信息
                </el-button>
                <!-- 整场 -->
                <el-button type="text" v-if="scope.row.bookingDetailType == 0"> 人数不限 </el-button>
                <!-- 闲置 -->
                <el-button type="text" v-if="scope.row.bookingDetailType == -2"> -- </el-button>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="changeRemark(scope.row, scope.$index)">编辑</el-button>
              </template>
            </el-table-column>
            <el-table-column label="状态" align="center">
              <template slot-scope="scope">
                <el-button type="text" v-if="scope.row.scheduleDetailStatus == -2">-</el-button>
                <el-button type="text" v-if="scope.row.scheduleDetailStatus == 0">待开始</el-button>
                <!-- <el-button
                type="text"
                icon="el-icon-close"
                v-if="scope.row.scheduleDetailStatus == -1"
                title="待开始"
              >待开始</el-button> -->
                <el-button type="text" icon="el-icon-close" v-if="scope.row.scheduleDetailStatus == -1"
                  title="已关闭">已关闭</el-button>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="120">
              <template slot-scope="scope">
                <el-button type="text" icon="el-icon-delete" title="删除"
                  @click="handleDelete(scope.row, scope.$index)"></el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="operate-btn-com">
            <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/60-xinzeng.png" title="新增"
              style="width: 20px; height: 20px" @click="addRow" />
            <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/659-yanyong.png" title="沿用"
              style="width: 20px; height: 20px" @click="continueUse" />
            <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/550.png" title="一键删除"
              style="width: 20px; height: 20px" @click="deleteAll" />
          </div>
        </div>
        </normal-card>
        <div class="bottom-btn">
            <!-- <el-button class="template" @click="handleTemplate">模板</el-button> -->
            <el-button type="primary" class="submit-btn" @click="handleSubmit">发布</el-button>
          </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 人数弹窗 -->
    <el-dialog :visible.sync="personVisible" width="30%" title="人数设定">
      <el-form label-width="100px">
        <el-form-item label="最小人数：">
          <el-select clearable v-model="minAmount">
            <el-option class="" v-for="item in planTime.min" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="最大人数：">
          <el-select clearable v-model="maxAmount">
            <el-option class="" v-for="item in planTime.max" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="handleCancelPerson">取 消</el-button>
        <el-button type="primary" @click="handleConfirmPerson">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 订单备注弹窗 -->
    <el-dialog :visible.sync="remarkVisible" width="30%" title="订单备注">
      <el-form label-width="100px">
        <el-form-item label="备注：">
          <el-input type="textarea" :rows="5" v-model="remark" placeholder="请输入备注内容"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="handleCancelRemark">取 消</el-button>
        <el-button type="primary" @click="handleConfirmRemark">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 锁场弹窗 -->
    <el-dialog :visible.sync="lockVisible" width="50%" style="margin-top: 160px" title="预定信息" @close="closeLockDialog">
      <el-form label-width="auto" :model="info">
        <div class="group-name">
          <div class="group"></div>
          <div class="name">主队</div>
        </div>
        <el-row :gutter="20" class="mt-4">
          <el-col :span="8">
            <el-form-item label="球队名：">
              <div class="w-48">
                <el-input v-model="lockTeamList[0].teamName" placeholder="输入球队名称"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预定人：">
              <div class="w-48">
                <el-input v-model="lockTeamList[0].contact" placeholder="输入预定人"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系方式：">
              <div class="w-48">
                <el-input v-model="lockTeamList[0].mobile" placeholder="输入联系方式"></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="group-name">
          <div class="group"></div>
          <div class="name">客队</div>
        </div>
        <el-row :gutter="20" class="mt-4">
          <el-col :span="8">
            <el-form-item label="球队名：">
              <el-input v-model="lockTeamList[1].teamName" placeholder="输入球队名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预定人：">
              <el-input v-model="lockTeamList[1].contact" placeholder="输入预定人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系方式：">
              <el-input v-model="lockTeamList[1].mobile" placeholder="输入联系方式"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeLockDialog">取消</el-button>
        <el-button type="primary" @click="handleSaveLock">保存</el-button>
      </span>
    </el-dialog>

    <!-- 选择球队弹窗 -->
    <el-dialog :visible.sync="aboutWarVisible" width="50%" title="选择报名球队" @close="closeAboutWar">
      <el-button type="primary" @click="addTeam" style="margin-bottom: 20px">新增</el-button>
      <el-table :data="teamList" border stripe>
        <el-table-column width="35">
          <template slot-scope="scope">
            <el-radio :label="scope.row.teamId" v-model="radio">&nbsp;</el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="teamName" label="球队名称" align="center"> </el-table-column>
        <el-table-column prop="level" label="水平" align="center"> </el-table-column>
        <el-table-column prop="mobile" label="电话" align="center"> </el-table-column>
        <el-table-column label="操作" style="width: 100%; white-space: nowrap" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="editTeam(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="conmitTeam">确认</el-button>
      </span>
    </el-dialog>

    <!-- 新增球队 -->
    <el-dialog :visible.sync="addTeamVisible" width="50%" :title="teamTitle" @close="closeTeamDialog">
      <el-form ref="form" :model="teamInfo" :rules="rules" label-width="120px" class="infoForm">
        <el-form-item label="球队Logo：">
          <div class="uploadArea">
            <div class="picTip">
              <f-image-upload v-model="teamInfo.logo" @input="changeLogo" style="margin-left: 0" :width="'100px'"
                :height="'100px'"></f-image-upload>
            </div>
          </div>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="球队名称：" prop="teamName">
              <el-input v-model="teamInfo.teamName" maxlength="10" placeholder="请输入球队名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="球队属性：" prop="teamProp">
              <el-select v-model="teamInfo.teamProp" placeholder="请选择球队属性">
                <el-option v-for="item in teamOption" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="电话号码：" prop="mobile">
              <el-input v-model="teamInfo.mobile" placeholder="请输入电话号码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="球队实力：" prop="level">
              <el-select v-model="teamInfo.level" placeholder="请选择球队实力">
                <el-option v-for="item in strength" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="约战次数：" prop="challengeCount">
              <el-input v-model="teamInfo.challengeCount" placeholder="请输入约战次数"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="队员人数：" prop="playerCount">
              <el-input v-model="teamInfo.playerCount" placeholder="请输入队员人数"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="球衣颜色：" prop="shirt">
              <el-select v-model="teamInfo.shirt" placeholder="本队球衣颜色">
                <el-option v-for="color of clothColors" :key="color" :label="color" :value="color"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="比赛备注：" prop="remark">
              <el-input type="textarea" v-model="teamInfo.remark" :autosize="{ minRows: 2, maxRows: 5 }"
                placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeTeamDialog">取消</el-button>
        <el-button type="primary" @click="saveTeam">保存</el-button>
      </span>
    </el-dialog>

    <!-- 计划模板 -->
    <el-dialog :visible.sync="templateVisible" width="30%" :title="teamTitle" @close="closePlanDialog">
      <div v-for="item in planTemplate" :key="item.planId">
        <div class="templateItem">
          <div>
            <el-radio :label="item.planId" v-model="planRadio">&nbsp;</el-radio>
          </div>
          <div>
            <div class="planName">
              <div class="planTitle">名称</div>
              <input v-model="item.planTitle" />
            </div>
            <div class="playType">
              <div class="type">
                <span class="planTitle">类型</span>
                <el-select v-model="item.planType">
                  <el-option label="日模板" value="日模板"></el-option>
                  <el-option label="周模板" value="周模板"></el-option>
                </el-select>
              </div>
              <div class="type" style="margin-left: 10px;">
                <span class="planTitle">数量</span>
                <input v-model="item.planNumber" />
              </div>
            </div>
          </div>
          <div class="Detail">
            <el-button type="text" @click="detailPlan(item)">查看 ></el-button>
          </div>
        </div>
      </div>
      <span slot="footer" class="planBtn">
        <el-button @click="createTemplate">+创建</el-button>
        <el-button type="primary">选用</el-button>
      </span>
    </el-dialog>
  </div>
</div>
</template>

<script>
import planTime from '../../apidata/planTime.json';
import FImageUpload from '@/common/components/Upload/f-image-upload.vue';
import { deepCopy } from '@/common/utils/common/index';
import normalCard from '@/common/components/normal/NormalCard';
import TopData from '../../common/components/normal/TopData.vue';
export default {
  components: { FImageUpload, normalCard, TopData},
  data() {
    return {
      venueList: [],
      teamList: [],
      teamInfo: {},
      rules: {
        teamName: [{ message: '请输入球队名称', required: true, trigger: 'blur' }],
        teamProp: [{ message: '请选择球队属性', required: true, trigger: 'change' }],
        mobile: [
          {
            pattern: /^[1][3,4,5,7,8][0-9]{9}$/,
            message: '请输入正确的手机号码',
            trigger: 'change',
          },
          { message: '请输入电话号码', required: true, trigger: 'blur' },
        ],
        level: [{ message: '请选择球队实力', required: true, trigger: 'change' }],
        shirt: [{ message: '请选择球衣颜色', required: true, trigger: 'change' }],
      },
      info: {},
      currentVenue: '',
      currentVenueInfo: {},
      planTime,
      aboutWarVisible: false,
      // 锁场
      lockVisible: false,
      lockTeamList: [
        { teamName: '', contact: '', mobile: '' },
        { teamName: '', contact: '', mobile: '' },
      ],

      addTeamVisible: false,
      teamTitle: '',
      selectDate: '',
      tableData: [],
      tableIndex: 0,
      personVisible: false,
      maxAmount: undefined,
      minAmount: undefined,
      remark: '',
      remarkVisible: false,

      // 枚举值，选项值
      bookingDetailTypeList: [
        // { value: 1, label: '锁场' },
        { value: 2, label: '约战' },
        { value: 0, label: '整场' },
        { value: 10, label: '散客' },
        // { value: 20, label: '飞盘' },
        // { value: -2, label: '闲置' },
      ],
      teamOption: [
        { value: '散客组队', label: '散客组队' },
        { value: '小区大队', label: '小区大队' },
        { value: '公司联队', label: '公司联队' },
        { value: '院校劲旅', label: '院校劲旅' },
        { value: '业余竞技', label: '业余竞技' },
        { value: '半职业队', label: '半职业队' },
      ],
      strength: [
        { value: '养生出汗', label: '养生出汗' },
        { value: '会踢能传', label: '会踢能传' },
        { value: '三俩球星', label: '三俩球星' },
        { value: '配合默契', label: '配合默契' },
        { value: '梦幻组合', label: '梦幻组合' },
        { value: '真刀真枪', label: '真刀真枪' },
      ],
      clothColors: ['混着穿', '白色', '蓝色', '红色', '黑色', '绿色', '橙色', '黄色'],
      radio: '',
      rowInfo: {},
      templateVisible: false,
      planTemplate: [
        {
          planTitle: "周三常用模板",
          planType: "日模板",
          planNum: "7条计划"
        },
        {
          planTitle: "备用常用一周版",
          planType: "周模板",
          planNum: "21条计划"
        }
      ],
      planRadio: ''
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.initSelectDate();
      this.getVenueList();
      this.getTeamList();
    },
    // 获取当天日期
    initSelectDate() {
      var today = new Date();
      var year = today.getFullYear();
      var month = this.fillZero(today.getMonth() + 1);
      var day = this.fillZero(today.getDate());
      this.selectDate = `${year}-${month}-${day}`;
    },
    fillZero(v) {
      return v < 10 ? `0${v}` : v;
    },
    prevDay() {
      let prevDateTime = new Date(this.selectDate).getTime() - 3600 * 1000 * 24;
      this.selectDate = this.timestampToTime(prevDateTime).substring(0, 10);
      this.getVenueScheduleList();
    },
    nextDay() {
      let nextDateTime = new Date(this.selectDate).getTime() + 3600 * 1000 * 24;
      this.selectDate = this.timestampToTime(nextDateTime).substring(0, 10);
      this.getVenueScheduleList();
    },
    changeDatePicker() {
      this.getVenueScheduleList();
    },
    //将10位或者13位时间戳转化为日期格式
    timestampToTime(timestamp) {
      if (String(timestamp).length == 10) {
        var unixtimestamp = new Date(timestamp * 1000);
        var year = 1900 + unixtimestamp.getYear();
        var month = '0' + (unixtimestamp.getMonth() + 1);
        var date = '0' + unixtimestamp.getDate();
        var hour = '0' + unixtimestamp.getHours();
        var minute = '0' + unixtimestamp.getMinutes();
        var second = '0' + unixtimestamp.getSeconds();
        return (
          year +
          '-' +
          month.substring(month.length - 2, month.length) +
          '-' +
          date.substring(date.length - 2, date.length) +
          ' ' +
          hour.substring(hour.length - 2, hour.length) +
          ':' +
          minute.substring(minute.length - 2, minute.length) +
          ':' +
          second.substring(second.length - 2, second.length)
        );
      } else {
        let currentTime = new Date(timestamp);
        let year = currentTime.getFullYear(),
          month = currentTime.getMonth() + 1,
          day = currentTime.getDate(),
          hour = currentTime.getHours(),
          minute = currentTime.getMinutes(),
          second = currentTime.getSeconds(),
          millSeconds = currentTime.getMilliseconds();
        let months = month < 10 ? '0' + month : month,
          days = day < 10 ? '0' + day : day,
          hours = hour < 10 ? '0' + hour : hour,
          minutes = minute < 10 ? '0' + minute : minute,
          seconds = second < 10 ? '0' + second : second,
          millSecondss =
            millSeconds < 10
              ? '00' + millSeconds
              : millSeconds < 100
                ? '0' + millSeconds
                : millSeconds;
        return (
          year +
          '-' +
          months +
          '-' +
          days +
          ' ' +
          hours +
          ':' +
          minutes +
          ':' +
          seconds +
          '.' +
          millSecondss
        );
      }
    },
    // 获取场地列表
    async getVenueList() {
      const { res } = await this.$http.get('/FieldVenue/GetFieldVenueList');
      this.venueList = res.data || [];
      if (this.venueList.length) {
        this.currentVenue = this.venueList[0].venueName;
        this.currentVenueInfo = this.venueList[0];
        this.getVenueScheduleList();
      }
    },
    // 获取场地安排计划
    async getVenueScheduleList() {
      const { res } = await this.$http.get(
        `/FieldVenue/VenueScheduleList?day=${this.selectDate}&venueId=${this.currentVenueInfo.venueId}`
      );
      if (res.isSuccess) {
        const data = res.data.scheduleList || []

        if (data.length) {
          this.tableData = data
        } else {
          this.tableData = []
          let count = (22 - this.currentVenueInfo.openingHours.split(':')[0]) / 2
          let begin = this.currentVenueInfo.openingHours.slice(0, 5)

          for (let i = 0; i < count; i++) {
            const obj = {
              venueId: this.currentVenueInfo.venueId,
              beginTime: begin,
              endTime: `${parseInt(begin.split(':')[0]) + 2}:${begin.split(':')[1]}`,
              bookType: undefined,
              bookingDetailType: undefined,
              price: '',
              checkTeamId: '',
              scheduleDetailStatus: '',
              teamList: [],
            }
            this.tableData.push(obj)
            begin = `${parseInt(begin.split(':')[0]) + 2}:${begin.split(':')[1]}`
          }
        }
      }
    },
    // 获取球场球队列表
    async getTeamList() {
      const { res } = await this.$http.get('/UserTeam/FieldTeamList');
      this.teamList = res.data || [];
    },

    // 判断订单时间是否过期，置灰
    timeDisabled(time) {
      if (!time) {
        return false;
      }
      // 删除单个计划判断当前时间戳是否大于开始时间戳15分钟
      const currentDateTime = new Date().getTime();
      const beginDateTime = new Date(`${this.selectDate} ${time}`).getTime();
      if (currentDateTime - beginDateTime > 15 * 60 * 1000) {
        return true;
      } else {
        return false;
      }
    },

    // 修改类型
    changeBookingDetailType(row) {
      if (row.bookingDetailType == 10) {
        row.max = this.currentVenueInfo.matchType * 3;
        row.min = this.currentVenueInfo.matchType * 2;
      } else if (row.bookingDetailType == 20) {
        row.max = this.currentVenueInfo.matchType * 4;
        row.min = this.currentVenueInfo.matchType * 2;
      } else {
        row.max = undefined;
        row.min = undefined;
      }
    },
    // 修改最多、最少人数
    changePersonAmount(row, index) {
      this.tableIndex = index;
      this.maxAmount = row.max;
      this.minAmount = row.min;
      this.personVisible = true;
    },
    handleCancelPerson() {
      this.personVisible = false;
      this.maxAmount = undefined;
      this.minAmount = undefined;
    },
    handleConfirmPerson() {
      if (this.minAmount && this.maxAmount) {
        if (this.minAmount > this.maxAmount) {
          this.$showError('最小人数不得大于最大人数');
          return;
        }
      }
      this.personVisible = false;
      this.tableData[this.tableIndex].max = this.maxAmount;
      this.tableData[this.tableIndex].min = this.minAmount;
      this.maxAmount = '';
      this.minAmount = '';
    },

    // 修改备注
    changeRemark(row, index) {
      this.tableIndex = index;
      this.remark = row.remark;
      this.remarkVisible = true;
    },
    // 取消备注按钮
    handleCancelRemark() {
      this.remark = '';
      this.remarkVisible = false;
    },
    // 确认备注按钮
    handleConfirmRemark() {
      this.remarkVisible = false;
      this.tableData[this.tableIndex].remark = this.remark;
      this.remark = '';
    },
    // 切换场地tab
    changeVenue(v) {
      const obj = this.venueList[v.index];
      this.currentVenue = obj.venueName;
      this.currentVenueInfo = obj;
      console.log(123456, this.currentVenueInfo)
      this.getVenueScheduleList();
    },
    // 约战弹窗
    aboutWar(row, index) {
      // 当前点击行如果有数据，默认勾选radio
      if (row.teamList && row.teamList.length) {
        this.radio = row.teamList[0].teamId || '';
      }
      this.aboutWarVisible = true;
      this.tableIndex = index;
    },
    // 关闭约战弹窗
    closeAboutWar() {
      this.radio = '';
    },

    //锁场弹窗
    fieldLock(row, index) {
      this.lockVisible = true;
      this.tableIndex = index;
      if (row.teamList && row.teamList.length) {
        this.lockTeamList = deepCopy(row.teamList);
      }
    },
    handleSaveLock() {
      this.lockVisible = false;
      this.tableData[this.tableIndex].teamList = this.lockTeamList;
      this.lockTeamList = [
        { teamName: '', contact: '', mobile: '' },
        { teamName: '', contact: '', mobile: '' },
      ];
    },
    closeLockDialog() {
      this.lockVisible = false;
      this.lockTeamList = [
        { teamName: '', contact: '', mobile: '' },
        { teamName: '', contact: '', mobile: '' },
      ];
    },

    // 编辑球队
    editTeam(row) {
      this.addTeamVisible = true;
      this.teamTitle = '修改球队';
      this.teamInfo = deepCopy(row);
    },
    //新增球队弹窗
    addTeam() {
      this.addTeamVisible = true;
      this.teamTitle = '新增球队';
    },
    // 关闭球队弹窗
    closeTeamDialog() {
      this.$refs.form.clearValidate();
      this.teamInfo = {};
      this.addTeamVisible = false;
    },
    // 保存球队
    saveTeam() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('确认保存球队信息吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(async () => {
            const { res } = await this.$http.post('/UserTeam/SaveFieldTeam', this.teamInfo);
            if (res.isSuccess) {
              this.$showSuccess('保存成功');
              this.addTeamVisible = false;
              this.getTeamList();
            }
          });
        }
      });
    },
    // 球队logo
    changeLogo(v) {
      console.log(v);
      this.teamInfo.logo = v;
    },
    // 确认该球队
    conmitTeam() {
      if (!this.radio) {
        this.$showError('请选择一支球队');
        return;
      }
      const selectTeamObj = this.teamList.find((el) => {
        return el.teamId === this.radio;
      });
      const checkTeam = [];
      checkTeam.push(selectTeamObj);
      this.tableData[this.tableIndex].teamList = checkTeam;
      this.aboutWarVisible = false;
    },
    // 新增一条数据
    addRow() {
      const obj = {
        venueId: this.currentVenueInfo.venueId,
        beginTime: '',
        endTime: '',
        bookType: undefined,
        bookingDetailType: undefined,
        price: '',
        checkTeamId: '',
        scheduleDetailStatus: '',
        teamList: [],
      };
      this.tableData.push(obj);
    },
    // 删除某条订单
    handleDelete(row, index) {
      // 删除单个计划判断当前时间戳是否大于开始时间戳15分钟
      const currentDateTime = new Date().getTime();
      const beginDateTime = new Date(`${this.selectDate} ${row.beginTime}`).getTime();
      if (currentDateTime - beginDateTime > 15 * 60 * 1000) {
        this.$showError('当前时间大于订单开始时间15分钟，不允许删除');
        return;
      }

      this.$confirm('确认删除该条数据吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        // 没有计划id，走物理删除
        if (!row.scheduleId) {
          this.tableData.splice(index, 1);
          return;
        }
        const params = {
          venueId: this.currentVenueInfo.venueId,
          scheduleIds: [row.scheduleId],
        };
        // const { res } = await this.$http.post(`/FieldVenue/RemoveSchedule/${row.scheduleId }`);
        const { res } = await this.$http.post(
          '/FieldVenue/RemoveSchedules/RemoveSchedules',
          params
        );
        if (res.isSuccess) {
          this.$showSuccess('删除成功');
          this.getVenueScheduleList();
        }
      });
    },
    // 删除所有
    deleteAll() {
      // 判断当前选中日期是否小于当前日期
      const year = new Date().getFullYear();
      const month = new Date().getMonth();
      const day = new Date().getDate();
      const currentDateTime = new Date(year, month, day, 0, 0, 0);
      const historyTime = new Date(`${this.selectDate} 00:00:00`).getTime();
      if (currentDateTime > historyTime) {
        this.$showError('历史日期不允许删除所有订单');
        return;
      }
      const params = {
        venueId: this.currentVenueInfo.venueId,
        scheduleIds: [],
      };
      this.tableData.map((el) => {
        if (el.scheduleId) {
          params.scheduleIds.push(el.scheduleId);
        }
      });
      this.$confirm('将清除本日所存在的所有订单计划(已支付订单不可用)，确认吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { res } = await this.$http.post(
          '/FieldVenue/RemoveSchedules/RemoveSchedules',
          params
        );
        if (res.isSuccess) {
          this.$showSuccess('删除成功');
          this.getVenueScheduleList();
        }
      });
    },
    // 沿用
    continueUse() {
      const params = {
        venueId: this.currentVenueInfo.venueId,
        day: this.selectDate,
      };
      this.$confirm(
        '将以当前场地计划自动配置至未来15天计划（未配置订单的日期），确认吗？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(async () => {
        const { res } = await this.$http.post('/FieldVenue/CopySchedules/CopySchedules', params);
        if (res.isSuccess) {
          this.$showSuccess('沿用成功');
          this.getVenueScheduleList();
        }
      });
    },
    // 发布订单
    handleSubmit() {
      let lists = [];
      lists = this.tableData.filter((el) => {
        if (el.bookingDetailType == 2) {
          return el.beginTime && el.endTime && el.price && el.teamList;
        } else {
          return el.beginTime && el.endTime && el.price;
        }
      });
      const params = {
        day: this.selectDate,
        scheduleList: lists,
        isDefault: false,
      };
      this.$confirm('请核实本日订单信息，发布后即时显示在您球场主页，确定发布吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { res } = await this.$http.post('/FieldVenue/SaveSchedule', params);
        if (res.isSuccess) {
          this.$showSuccess('发布成功');
          this.getVenueScheduleList();
        }
      });
    },
    // 计划模板
    handleTemplate() {
      this.templateVisible = true
    },
    // 关闭计划模板弹窗
    closePlanDialog() { },
    // 模板详情
    detailPlan() {
      this.$router.push({
        name: 'planTemplate',
        query: this.currentVenueInfo

      });
    },
    // 新增模板
    createTemplate() {
      this.$router.push({
        name: 'planTemplate',
        query: this.currentVenueInfo
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.overviewCommand {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;

  .bread-crumb {
    height: 30px;
  }

  .el-tabs {
    height: calc(100% - 30px);

    /deep/ .el-tabs__content {
      height: calc(100% - 39px);

      .el-tab-pane {
        height: 100%;
        display: flex;
        flex-flow: column;
      }
    }

    .select-date-com {
      height: 60px;
      display: flex;
      align-items: center;
    }

    .schedule-table {
      flex: 1;

      /deep/ .el-table__body-wrapper {
        overflow-y: auto;
        height: calc(100% - 48px);
      }
    }
  }
}

.group-name {
  display: flex;
  align-items: center;

  .group {
    width: 5px;
    height: 22px;
    background-color: #134f7a;
    border-radius: 2px;
  }

  .name {
    margin-left: 10px;
    font-weight: 700;
    font-size: 20px;
  }
}

.tag-com {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tag {
    width: 15%;
  }
}

.operate-btn-com {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15px;
  border-bottom: 1px solid #333;
background-color: #fff;
  img {
    cursor: pointer;
  }
}

.submit-btn {
  width: 200px;
  margin: 0 auto;
  margin-top: 20px;
}

.bottom-btn {
  display: flex;
  justify-content: space-around;
  align-content: center;
  margin-top: 10px;
}

.template {
  width: 200px;
  margin: 0 auto;
  margin-top: 20px;
}

.templateItem {
  background-color: #fff;
  margin-top: 20px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;

  input {
    border: 1px solid #bdbcbc;
    text-align: center;
  }

  .planName {
    display: flex;
    text-align: center;
  }

  .planTitle {
    background: #bdbcbc;
    width: 40px;
    text-align: center;
    line-height: 32px;
  }

  .playType {
    display: flex;
    margin-top: 20px;
  }

  .type {
    display: flex;
  }

  .Detail {}
}

.planBtn {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
